/*=========================================================================================
  File Name: moduleChatMutations.js
  Description: Chat Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_TEAM_CHAT_OPEN(state, data) {
    state.teamChatOpen = data;
  },
  SET_CHAT_REPLY_MESSAGE(state, data) {
    state.chatReplyMessage = data;
  },
  SET_WHATSAPP_API_360_DIALOG_TEMPLATES(state, data) {
    state.whatsappApi360DialogTemplates = data;
  },
  SET_WHATSAPP_API_360_DIALOG_CURRENT_CHAT(state, data) {
    state.whatsappApi360DialogCurrentChat = data;
  },
  RESET_DATA(state) {
    state.searchQuery = "";
    state.chats = [];
    state.chats360 = [];
    state.currentChat = { participants: [], messages: [] };
    state.currentOperator = {};
    state.inboxOperatorsArray = [];
    state.inboxOperatorsArrayStored = [];
    state.inboxChannels = [];
    state.activeContact = {};
    state.attendance = false;
    //state.chatContacts = [];
    state.currentAttendance = [];
    state.channel = "Website";
    state.activeTab = 1;
    state.activeTabName = ["Campanhas", "Aguardando Atendimento", "Em Atendimento"];
    state.activeProfileSidebar = false;
    state.activeChatUser = null;
    state.userProfileId = -1;
    state.typedMessage = "";
    state.messageList = [];
    state.messageListLocalPics = [];
    state.participants = [];
    state.contactIndex = -1;
    state.blob = null;
    state.audioSource = null;
    state.recorder = null;
    state.fileInput = null;
    state.allIntentions = [];
    state.allEntities = [];
    state.entitiesArray = [];
    state.intentionsArray = [];
    state.intentionsArrayStored = [];
    state.notificationCounter = 0;
    state.contactsPagination = 30;
    state.inboxQuickMessages = [];
    state.inboxAutoMessages = [];
  },
  SET_SEARCH_QUERY(state, query) {
    state.searchQuery = query;
  },
  // curerntOperator
  SET_CURRENT_OPERATOR(state, data) {
    state.currentOperator = Object.assign({}, data);
  },
  // inboxOperators
  SET_INBOX_OPERATORS_ARRAY_STORED(state, operators) {
    let treatedOperators = [];
    treatedOperators = operators.map((operator) => {
      return {
        label: operator.name,
        value: operator.operatorData._id,
        intentions: operator.operatorData.intentions,
        entities: operator.operatorData.entities,
        acl: operator.operatorData.acl,
        lastSeenDate: operator.lastSeenDate || null
      };
    });
    treatedOperators.push({ label: "Sem operador", value: "" });
    state.inboxOperatorsArrayStored = Object.assign([], treatedOperators);
  },
  SET_INBOX_OPERATORS_ARRAY(state, inboxOperatorsArray) {
    state.inboxOperatorsArray = Object.assign([], inboxOperatorsArray);
  },
  //chats
  ADD_CHATS_ITEM(state, data) {
    state.chats.push(data);
  },
  ADD_CHATS_ITEM_360(state, data) {
    state.chats360.push(data);
  },
  // inboxChannels
  SET_INBOX_CHANNELS(state, inboxChannels) {
    state.inboxChannels = inboxChannels;
  },
  // all intentions
  SET_ALL_INTENTIONS(state, intentions) {
    state.allIntentions = intentions;
  },
  // intentionsArrayStored
  SET_INTENTIONS_ARRAY_STORED(state, intentionsArray) {
    state.intentionsArrayStored = Object.assign([], intentionsArray);
  },
  ADD_ON_INTENTIONS_ARRAY_STORED(state, intention) {
    state.intentionsArrayStored.unshift(intention);
  },
  // intentionsArray
  SET_INTENTIONS_ARRAY(state, intentionsArray) {
    if (typeof intentionsArray === "string")
      state.intentionsArray = [intentionsArray];
    else
      state.intentionsArray = intentionsArray;
  },
  // entitiesArray
  SET_ENTITIES_ARRAY(state, entitiesArray) {
    state.entitiesArray = Object.assign([], entitiesArray);
  },
  SET_ALL_ENTITIES(state, allEntities) {
    state.allEntities = allEntities;
  },
  // activeContact
  SET_ACTIVE_CONTACT(state, data) {
    state.activeContact = Object.assign({}, data);
  },
  // updateActiveContactField
  UPDATE_ACTIVE_CONTACT_FIELD(state, payload) {
    state.activeContact[payload.field] = payload.data;
  },
  REMOVE_ELEMENT_ON_CHAT_CONTACTS(state, data) {
    if (data && state.chatContacts[data]) delete state.chatContacts[data];
  },
  // currentAttendance
  SET_CURRENT_ATTENDANCE(state, data) {
    state.currentAttendance = data;
  },
  ADD_ON_CURRENT_ATTENDANCE(state, data) {
    state.currentAttendance.push(data);
  },
  // channel
  SET_CHANNEL(state, data) {
    state.channel = data;
  },
  // activeTab
  SET_ACTIVE_TAB(state, data) {
    state.activeTab = data;
  },
  // activeProfileSidebar
  SET_ACTIVE_PROFILE_SIDEBAR(state, data) {
    state.activeProfileSidebar = data;
  },
  // activeChatUser
  SET_ACTIVE_CHAT_USER(state, data) {
    state.activeChatUser = data;
  },
  // userProfileId
  SET_USER_PROFILE_ID(state, data) {
    state.userProfileId = data;
  },
  // typedMessage
  SET_TYPED_MESSAGE(state, data) {
    const typedMessageIndex = state.typedMessage
      ? state.typedMessage.findIndex((el) => el.id === data.id)
      : -1;
    if (typedMessageIndex > -1) {
      if (data.message === "") state.typedMessage.splice(typedMessageIndex, 1);
      else state.typedMessage[typedMessageIndex].message = data.message;
    } else {
      if (!state.typedMessage) state.typedMessage = [];
      state.typedMessage.push(data);
    }
  },
  // messageList
  SET_MESSAGE_LIST(state, data) {
    state.messageList = data;
  },
  REMOVE_MESSAGE_ON_MESSAGE_LIST(state, messageID) {
    const messageFoundedIndex = state.messageList.findIndex((messageItem) => {
      return messageItem.id === messageID;
    });
    if (messageFoundedIndex > -1)
      state.messageList.splice(messageFoundedIndex, 1);
  },
  SORT_MESSAGE_LIST(state) {
    state.messageList.sort((a, b) => {
      if (typeof a.timestamp === "string")
        a.timestamp = new Date(a.timestamp).getTime();
      if (typeof b.timestamp === "string")
        b.timestamp = new Date(b.timestamp).getTime();
      return a.timestamp - b.timestamp;
    });
  },
  UPDATE_MESSAGE_ON_MESSAGE_LIST(state, data) {
    const { _id, messageID, uid, payload } = data;

    const messageFoundedIndex = state.messageList.findIndex((messageItem) => {
      if (_id && messageItem._id) return messageItem._id === _id;
      if (messageItem.id && messageID) return messageItem.id === messageID;
      if (messageItem.uid && uid) return messageItem.uid === uid;
      return -1;
    });
    if (messageFoundedIndex > -1) {
      state.messageList[messageFoundedIndex] = Object.assign(
        state.messageList[messageFoundedIndex],
        payload
      );
      state.messageList = Object.assign([], state.messageList);
    } else if (data.insertIfNull) {
      state.messageList.push(payload);
      state.participants.push({
        id: payload.author,
        name: payload.authorName,
        imageUrl: payload.authorImageUrl,
      });
    }
  },
  UPDATE_MESSAGE_ID_ON_MESSAGE_LIST(state, data) {
    const { oldMessageID, newMessageID } = data;
    const compareMessage = (messageItem) => {
      return messageItem.id === oldMessageID;
    };
    const messageFoundedIndex = state.messageList.findIndex(compareMessage);
    if (messageFoundedIndex > -1) {
      state.messageList[messageFoundedIndex].id = newMessageID;
      state.messageList = Object.assign([], state.messageList);
    }
  },
  SET_MESSAGE_LIST_LOCAL_PICS(state, data) {
    state.messageListLocalPics.push(data);
  },
  // participants
  SET_PARTICIPANTS(state, data) {
    state.participants = data;
  },
  // blob
  SET_BLOB(state, data) {
    state.blob = data;
  },
  // audioSource
  SET_AUDIO_SOURCE(state, data) {
    state.audioSource = data;
  },
  // recorder
  SET_RECORDER(state, data) {
    state.recorder = data;
  },
  // Quick message audio blob
  SET_QCK_MESSAGE_BLOB(state, data) {
    state.qckMessageBlob = data;
  },
  // Quick Message audio source
  SET_QCK_MESSAGE_AUDIO_SOURCE(state, data) {
    state.qckMessageAudioSource = data;
  },
  // Quick message audio recorder
  SET_QCK_MESSAGE_RECORDER(state, data) {
    state.qckMessageRecorder = data;
  },
  // notificationCounter
  SET_NOTIFICATION_COUNTER(state, data) {
    state.notificationCounter = data;
  },
  // quick messages
  SET_INBOX_QUICK_MESSAGES(state, inboxQuickMessages) {
    state.inboxQuickMessages = Object.assign([], inboxQuickMessages);
  },
  // auto messages
  SET_INBOX_AUTO_MESSAGES(state, inboxAutoMessages) {
    state.inboxAutoMessages = Object.assign([], inboxAutoMessages);
  },
  SET_CURRENT_TAB_CHATS(state, data) {
    state.currentTabConversations = data;
  },
  SET_INBOX_WARNING_FLAGS(state, data) {
    state.inboxWarningFlags = data;
  },
  SET_INBOX_BLOCK_WAITING_SERVICE_TAB(state, data) {
    state.blockWaitingServiceTab = data;
  },
};
