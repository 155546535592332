/*=========================================================================================
  File Name: moduleContactActions.js
  Description: Contact Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
  getPhases({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/p/chat/inbox/phases", {
        params: { acc: "" + payload.acc + "" },
      }).then((response) => {
        commit("SET_PHASES", response.data.data);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getContactsBoard({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_CONTACT_PHASE", []);
      const params = { acc: "" + payload.acc + "" };
      if (payload.limit != null) params.limit = payload.limit;
      if (payload.page != null) params.page = payload.page;
      if (payload.search != null) params.search = payload.search;
      if (payload.tags != null) params.tags = payload.tags;
      if (payload.phase !== "all") params.phase = payload.phase;
      params.monthFilter = payload.monthFilter;
      params.currentYear = payload.currentYear;
      if (payload.blocked) params.blocked = payload.blocked;
      axios.get("/p/chat/contact/list", {
          params: params,
        })
        .then((response) => {
          let allContacts = typeof response.data.data.items != "undefined" ? response.data.data.items : 0;
          let count = 0;
          if (allContacts.length > 0) {
            allContacts.forEach((contact) => {
              let dateContact = new Date(contact.createdAt);
              contact.createdAt = ("0" + dateContact.getDate()).slice(-2) + "/" + ("0" + (dateContact.getMonth() + 1)).slice(-2) + "/" + dateContact.getFullYear() || "";
              contact.type = contact.type[0].toUpperCase() + contact.type.substring(1);
              contact.tags = [];
              dispatch("setContactInPhase", contact);
            });
            count = response.data.data.count;
          }
          resolve({ count: count });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setContactInPhase({ commit }, contact) {
    commit("SET_CONTACT_IN_PHASE", contact);
  },
  updateContactBefore({ commit }, payload) {
    commit("UPDATE_CONTACT_BEFORE", payload);
  },
  updateContactAfter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let data = {};
      data.name = "name" in payload ? payload.name : "";
      data.email = "email" in payload ? payload.email : "";
      data.phone = "phone" in payload ? payload.phone : "";
      data.profilePic = "profilePic" in payload ? payload.profilePic : "";
      data.type = "type" in payload ? payload.type : "";
      data.phase = "phase" in payload ? payload.phase : "";
      data.inbox = "inbox" in payload ? payload.inbox : "";
      data.chat = "chat" in payload ? payload.chat : "";
      data.acc = "acc" in payload ? payload.acc : "";
      data.status = "status" in payload ? payload.status : "";
      axios({
        method: "put",
        url: `/p/chat/contact/${payload._id}`,
        data: {
          data,
          acc: data.acc,
        },
      })
        .then((result) => {
          commit("UPDATE_CONTACT_AFTER", payload);
          commit("UPDATE_CONTACT_PHASE", payload);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateContactProp({ commit }, payload) {
    return new Promise((resolve) => {
      commit("UPDATE_CONTACT_PROP", payload);
      commit("UPDATE_CONTACT_PHASE_PROP", payload);
      resolve();
    });
  },
  refreshContact({ commit }, { index, contactRef }) {
    if (contactRef === "board") commit("CONTACT_CONTACT_PHASE_REFRESH", index);
    else commit("UPDATE_CONTACT_PHASE", index);
  },
  resetContactsPhase({ commit }) {
    commit("SET_CONTACT_PHASE", []);
  },
  setActiveProfileSidebar({ commit }, data) {
    commit("SET_ACTIVE_PROFILE_SIDEBAR", data);
  },
};
