/*=========================================================================================
  File Name: moduleChatState.js
  Description: Chat Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  teamChatOpen: false,
  chatReplyMessage: null,
  whatsappApi360DialogTemplates: [],
  whatsappApi360DialogCurrentChat: null,
  searchQuery: "",
  chats: [],
  chats360: [],
  currentChat: { participants: [], messages: [] },
  currentOperator: {},
  inboxOperatorsArray: [],
  inboxOperatorsArrayStored: [],
  inboxChannels: [],
  activeContact: {},
  attendance: false,
  chatContacts: {},
  currentAttendance: [],
  channel: "",
  activeTab: 1,
  activeTabName: ["Aguardando Atendimento", "Em Atendimento"],
  activeProfileSidebar: false,
  activeChatUser: null,
  userProfileId: -1,
  typedMessage: [],
  messageList: [],
  messageListLocalPics: [],
  participants: [],
  contactIndex: -1,
  //audio
  blob: null,
  audioSource: null,
  recorder: null,
  qckMessageBlob: null,
  qckMessageAudioSource: null,
  qckMessageRecorder: null,
  fileInput: null,
  allIntentions: [],
  intentionsArray: [],
  intentionsArrayStored: [],
  entitiesArray: [],
  allEntities: [],
  notificationCounter: 0,
  contactsPagination: 30,
  inboxQuickMessages: [],
  inboxAutoMessages: [],
  currentTabConversations: [],
  inboxWarningFlags: {},
  blockWaitingServiceTab: false,
};
