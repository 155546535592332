/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  setTeamChatOpen({ commit }, data) {
    commit("SET_TEAM_CHAT_OPEN", data);
  },
  setChatReplyMessage({ commit }, data) {
    commit("SET_CHAT_REPLY_MESSAGE", data);
  },
  // quickMessages
  setWhatsappApi360DialogTemplates({ commit }, data) {
    commit("SET_WHATSAPP_API_360_DIALOG_TEMPLATES", data);
  },
  setWhatsappApi360DialogCurrentChat({ commit }, data) {
    commit("SET_WHATSAPP_API_360_DIALOG_CURRENT_CHAT", data);
  },
  reset({ commit }) {
    commit("RESET_DATA");
  },
  setSearchQuery({ commit }, query) {
    commit("SET_SEARCH_QUERY", query);
  },
  setChatsItem({ commit }, data) {
    commit("ADD_CHATS_ITEM", data);
  },
  setChatsItem360({ commit }, data) {
    commit("ADD_CHATS_ITEM_360", data);
  },
  // currentOperator
  setCurrentOperator({ commit }, data) {
    commit("SET_CURRENT_OPERATOR", data);
  },
  // inboxOperators
  setInboxOperatorsArrayStored({ commit }, data) {
    commit("SET_INBOX_OPERATORS_ARRAY_STORED", data);
  },
  setInboxOperatorsArray({ commit }, data) {
    commit("SET_INBOX_OPERATORS_ARRAY", data);
  },
  // inboxChannels
  setInboxChannels({ commit }, data) {
    commit("SET_INBOX_CHANNELS", data);
  },
  // allIntentions
  setAllIntentions({ commit }, data) {
    commit("SET_ALL_INTENTIONS", data);
  },
  // intentionsStored
  setIntentionsArrayStored({ commit }, data) {
    commit("SET_INTENTIONS_ARRAY_STORED", data);
  },
  addOnIntentionsArrayStored({ commit }, data) {
    commit("ADD_ON_INTENTIONS_ARRAY_STORED", data);
  },
  // intentions
  setIntentionsArray({ commit }, data) {
    commit("SET_INTENTIONS_ARRAY", data);
  },
  // entitiesStored
  setEntitiesArray({ commit }, data) {
    commit("SET_ENTITIES_ARRAY", data);
  },
  setAllEntities({ commit }, data) {
    commit("SET_ALL_ENTITIES", data);
  },
  // activeContact
  setActiveContact({ commit }, data) {
    commit("SET_ACTIVE_CONTACT", data);
  },
  updateActiveContactField({ commit }, { field, data }) {
    commit("UPDATE_ACTIVE_CONTACT_FIELD", { field, data });
  },
  removeElementOnChatContacts({ commit }, data) {
    commit("REMOVE_ELEMENT_ON_CHAT_CONTACTS", data);
  },
  // currentAttendance
  setCurrentAttendance({ commit }, data) {
    commit("SET_CURRENT_ATTENDANCE", data);
  },
  addOnCurrentAttendance({ commit }, data) {
    commit("ADD_ON_CURRENT_ATTENDANCE", data);
  },
  // channel
  setChannel({ commit }, data) {
    commit("SET_CHANNEL", data);
  },
  // activeTab
  setActiveTab({ commit }, data) {
    commit("SET_ACTIVE_TAB", data);
  },
  // activeProfileSidebar
  setActiveProfileSidebar({ commit }, data) {
    commit("SET_ACTIVE_PROFILE_SIDEBAR", data);
  },
  // activeChatUser
  setActiveChatUser({ commit }, data) {
    commit("SET_ACTIVE_CHAT_USER", data);
  },
  // userProfileId
  setUserProfileId({ commit }, data) {
    commit("SET_USER_PROFILE_ID", data);
  },
  // typedMessage
  setTypedMessage({ commit }, data) {
    commit("SET_TYPED_MESSAGE", data);
  },
  // messageList
  setMessageList({ commit }, data) {
    commit("SET_MESSAGE_LIST", data);
  },
  addMessageList({ commit }, data) {
    commit("UPDATE_MESSAGE_ON_MESSAGE_LIST", {
      _id: data._id || -1,
      messageID: data.id,
      payload: data,
      insertIfNull: true,
    });
  },
  rmMessageList({ commit }, messageID) {
    commit("REMOVE_MESSAGE_ON_MESSAGE_LIST", messageID);
  },
  updateMessageOnMessageList({ commit }, data) {
    commit("UPDATE_MESSAGE_ON_MESSAGE_LIST", data);
  },
  sortMessageList({ commit }, data) {
    commit("SORT_MESSAGE_LIST", data);
  },
  updateMessageIDOnMessageList({ commit }, data) {
    commit("UPDATE_MESSAGE_ID_ON_MESSAGE_LIST", data);
  },
  addMessageListLocalPics({ commit }, data) {
    commit("SET_MESSAGE_LIST_LOCAL_PICS", data);
  },
  // participants
  setParticipants({ commit }, data) {
    commit("SET_PARTICIPANTS", data);
  },
  // blob
  setBlob({ commit }, data) {
    commit("SET_BLOB", data);
  },
  // audioSource
  setAudioSource({ commit }, data) {
    commit("SET_AUDIO_SOURCE", data);
  },
  // recorder
  setRecorder({ commit }, data) {
    commit("SET_RECORDER", data);
  },
  // Quick message blob
  setQckMessageBlob({ commit }, data) {
    commit("SET_QCK_MESSAGE_BLOB", data);
  },
  // Quick message audioSource
  setQckMessageAudioSource({ commit }, data) {
    commit("SET_QCK_MESSAGE_AUDIO_SOURCE", data);
  },
  // Quick message audio recorder
  setQckMessageAudioRecorder({ commit }, data) {
    commit("SET_QCK_MESSAGE_RECORDER", data);
  },
  // fileInput
  setFileInput({ commit }, data) {
    commit("SET_FILE_INPUT", data);
  },
  // notificationCounter
  setNotificationCounter({ commit }, data) {
    commit("SET_NOTIFICATION_COUNTER", data);
  },
  // quickMessages
  setInboxQuickMessages({ commit }, data) {
    commit("SET_INBOX_QUICK_MESSAGES", data);
  },
  // autoMessages
  setInboxAutoMessages({ commit }, data) {
    commit("SET_INBOX_AUTO_MESSAGES", data);
  },
  // currentTabConversations
  currentTabConversations({ commit }, data) {
    commit("SET_CURRENT_TAB_CHATS", data);
  },
  //inboxWarningFlags
  setInboxWarningFlags({ commit }, data) {
    commit("SET_INBOX_WARNING_FLAGS", data);
  },
  //blockWaitingServiceTab
  setBlockWaitingServiceTab({ commit }, data) {
    commit("SET_INBOX_BLOCK_WAITING_SERVICE_TAB", data);
  },
};
