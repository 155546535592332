// en, de, fr, pt
export default {
  en: {
    ServiceQueue: "Service Queue",
    campaigns: {
      lastTableUpdate: 'Last update'
    },
    Note: "Note",
    Notes: "Notes",
    QuickMessageCreated: "Quick message created",
    Media: "Media",
    Opening: "Opening",
    Closing: "Closing",
    triggerIntegrationLabelOpening: "Conversation opening",
    triggerIntegrationLabelClosing: "Conversation closing",
    triggerIntegrationLabelCampaign: "First answer campaign",
    triggerIntegrationLabelAll: "All",
    Tomorrow: "Tomorrow",
    Today: "Today",
    Year: "Year",
    DoYouWantLogout: "Do you want to logout",
    SyncTemplates: "Sync Templates",
    SyncingTemplates: "Syncing Templates",
    Photo: "Photo",
    Remove: "Remove",
    Country: "Country",
    Language: "Language",
    Chatbots: "Chatbots",
    Reset: "Reset",
    Save: "Save",
    Cancel: "Cancel",
    FirstName: "First Name",
    LastName: "Last Name",
    Phone: "Phone",
    Dashboard: "Dashboard",
    eCommerce: "eCommerce",
    Apps: "Apps",
    Todo: "Todo",
    Email: "Email",
    Calendar: "Calendar",
    FullCalendar: "Full Calendar",
    SimpleCalendar: "Simple Calendar",
    Shop: "Shop",
    ItemDetails: "Item Details",
    Cart: "Cart",
    WishList: "Wish List",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Data List",
    ListView: "List View",
    ThumbView: "Thumb View",
    Grid: "Grid",
    Vuexy: "duotalk",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    CardActions: "Card Actions",
    CardColors: "Card Colors",
    Table: "Table",
    agGridTable: "agGrid Table",
    Components: "Components",
    Alert: "Alert",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    Buttons: "Buttons",
    ButtonGroup: "Button Group",
    Body: "Body",
    Header: "Header",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
    Loading: "Loading",
    Navbar: "Navbar",
    Notifications: "Notifications",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Select: "Select",
    SelectAll: "Select All",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number Input",
    Textarea: "Textarea",
    FormLayouts: "Form Layouts",
    FormWizard: "Form Wizard",
    FormValidation: "Form Validation",
    FormInputGroup: "Form Input Group",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    Register: "Register",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    Error: "Error",
    404: "404",
    500: "500",
    Title404: "404 - Page Not Found",
    Description404:
      "It appears that the content you tried to access is not available",
    Button404: "Access Account",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Profile: "Profile",
    User: "User",
    View: "View",
    Edit: "Edit",
    UserSettings: "User Settings",
    ChatbotSettings: "Chatbot Settings",
    ChatbotBehavior: "Behavior Settings",
    Invoice: "Invoice",
    FAQ: "FAQ",
    SearchUsers: "Search for users",
    Search: "Search",
    KnowledgeBase: "Knowledge Base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    QuillEditor: "Quill Editor",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    // "Import/Export": 'Import/Export',
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export Selected",
    Others: "Others",
    MenuLevels: "Menu Levels",
    MenuLevel2p1: "Menu Level 2.1",
    MenuLevel2p2: "Menu Level 2.2",
    MenuLevel3p1: "Menu Level 3.1",
    MenuLevel3p2: "Menu Level 3.2",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    demoText:
      "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
    CreatedChatbots: "Chatbots",
    Delete: "Delete",
    Inactivate: "Inactivate",
    Activate: "Activate",
    Active: "Active",
    Inactive: "Inactive",
    SnippetCode: "Snippet Code",
    SnippetInstructions:
      "Copie o código abaixo e cole no seu site, antes do fechamento do </head>",
    IntelligentX: "inteligent {0}",
    CopyCode: "Copy Code",
    Copy: "Copy",
    Copied: "Copied",
    CreateYourFirstChatbot:
      "Create your first Chatbot from the templates below",
    TemplatesReadyToUse: "Templates ready to use",
    All: "All",
    AllChatbots: "All Chatbots",
    UnexpectedError: "Unexpected Error",
    UnexpectedErrorLoadX: "An unexpected error occurred while loading {0}",
    ConfirmDeleteOfX: "Are you sure you want to delete the {0}",
    ConfirmDeletedPermanentlyX: "{0} will be deleted permanently",
    TheChatbot: "The Chatbot",
    Deleted: "Deleted",
    DeletedSuccessfuly: "It was deleted",
    UnexpectedErrorDeleteX: "Unexpected error to delete {0}",
    ChatbotName: "Chatbot Name",
    ChooseChatbot: "Which chatbot do you want to use?",
    WebsiteToInstall: "Website to install chatbot",
    EmailToReceiveLeads: "Email to receive your leads",
    InformAWhatsapp: "WhatsApp number",
    CreateChatbot: "Create Chatbot",
    ChatbotExample: "Chatbot Example",
    WidgetColor: "Chatbot Color",
    WidgetCustomization: "Customization",
    SocialNetwork: "Social Network",
    ChatbotGreeting: "Greeting",
    WidgetPosition: "Widget Position",
    FirstMessage: "First Message",
    AllowedAvatar: "Allowed JPG, GIF or PNG. Max size of 800kB",
    ChatbotCreatedSuccessfuly: "Chatbot {0} created successfully",
    ChatbotEditedSuccessfuly: "Chatbot edited successfully",
    FollowTheInstructionsOnScreen:
      "Install it by following the instructions on screen",
    EditChatbot: "Edit Chatbot",
    EditFlow: "Edit Flow",
    EditChatpage: "Edit Chatpage",
    ChatpageTitle: "Chatpage Title",
    ChatpagePreview: "Chatpage Preview",
    MessageDescription: "Message Description",
    MetaTitle: "Meta Title (SEO)",
    metaDescription: "Meta Description (SEO)",
    ChatpageLink: "Chatpage Link",
    Of: "of",
    Complete: "Complete",
    Incomplete: "Incomplete",
    Hour: "Hour",
    Integration: "Integration",
    Name: "Name",
    name: "Name",
    Chatbot: "Chatbot",
    Home: "Home",
    Leads: "Leads",
    CurrentPassword: "Current password",
    NewPassword: "New password",
    ConversationsLimit: "Limit of simultaneous calls",
    ConfirmNewPassword: "Confirm new password",
    PasswordChanged: "Password changed",
    InformationChanged: "Changes saved",
    YourProfile: "Profile",
    YourAccount: "Your Account",
    YourTeam: "Your Team",
    LabelDistributionQueue: "Distribution Queue",
    Team: "Team",
    Logout: "Logout",
    LogoutSuccess: "Logout successfully",
    LoginWithFacebook: "Login with Facebook successfully",
    LoginWithInstagram: "Login with Instagram successfully",
    RemindMe: "Remind me",
    SignUp: "Sign Up",
    Create: "Create",
    Success: "Success",
    General: "General",
    ChangePassword: "Change Password",
    Settings: "Settings",
    Filters: "Filters",
    NotInformed: "Not Informed",
    ResetPasswordMessage: "An email was sent with password reset instructions",
    LoginWelcomeBack: "Welcome back, access your account",
    ResetPasswordMessageWindow:
      "Enter the email address associated with your account and we will send you a link to reset your password",
    GoBack: "Go Back",
    InvalidEmail: "Insert a valid e-mail",
    Continue: "Continue",
    AccReadyMessage: "Register and login",
    PersonalInformation: "User Information",
    TellYourName: "First tell me your name",
    TellYourEmail: "Now your email",
    CreateAPassword: "Create a password for your account",
    ConfirmPassword: "Confirm your password",
    AccInformation: "Account Information",
    CompanyName: "Company Name",
    TellCompanyName: "What is your company name",
    TellCompanyIndustry: "What is your company industry",
    ChoosePlan: "Choose a plan",
    ChangeMyPlan: "Change my Plan",
    ChoosePlanPremium: "Choose to Premium",
    ChoosePlanPro: "Choose to PRO",
    DontSupportPlanChatbotPremium:
      "To create Chatbot with PRO Template you must change the PRO Plan",
    GetFree30Days: "Upgrade now and get 30 days free",
    NoChoosePlan: "No, I want to continue with ",
    AccToChoosePlan: "",
    DiscontCoupon: "Promo Cupom",
    FormValidationGeneric: "Fill out all fields correctly in order to continue",
    CopiedToClipboard: "Copied to clipboard",
    VerificationCode: "Verification code",
    VerifyUserMessage:
      "Enter the code you received by email to verify your account. Didn't received it?",
    ResendVerificationCode: "Resend Code",
    Verify: "Verify",
    VerifyUserEmailMessage: "Email sent with verification code",
    RegisteredSuccessfully: "All set, welcome to Duotalk!",
    Welcome: "Welcome",
    HiHowAreYou: "Hello, how are you?",
    ToDuotalk: "to Duotalk",
    CreateNowChatbot: "you can now create your Chatbot!",
    Chats: "Chats",
    TrialDaysLeft: "{0} {1} left",
    Days: "Days",
    YourPlanIs: "Your plan is",
    Has: "Has",
    OnTotalOf: "On a total of",
    Talks: "Talks",
    With: "With",
    Users: "Users",
    FreeTierTimeLeft: "of free tier left",
    ThereIs: "There is",
    Chat: "Chat",
    YourDuoTalkAcc: "Your Duotalk account",
    Account: "Account",
    UserList: "User List",
    AccountEdit: "Edit Account",
    CompanyIndustry: "Company Industry",
    Plan: "Plan",
    Price: "Price",
    BillingFrequency: "Billing Frequency",
    Month: "Month",
    Months: "Months",
    Monthly: "Monthly",
    ChangePlan: "Change Plan",
    Billing: "Billing",
    BillingHistory: "Billing History",
    NextPayment: "Next Payment",
    CreatedAt: "Created at",
    CreatedBy: "Created by",
    Receipt: "Receipt",
    Date: "Date",
    Pending: "Pending",
    Reconnecting: "Reconnecting",
    Each: "Each",
    NewUser: "New User",
    AddUser: "Add User",
    AddButton: "Add Button",
    InviteUser:
      "Type a full email address to add an existing account or to invite a new user",
    UserInvitedSuccessfully: "User invited successfuly",
    CreateAccount: "Create a Duotalk account",
    CreateAccountMessage: "Fill the below form to create a new account",
    Password: "Password",
    ConfirmRemoveUserAccess: "Confirm Remove",
    ConfirmRemoveUserAccessMessage: "Confirm remove {0} access?",
    AccEdited: "Account updated",
    AccCreated: "Account created",
    AccPlanEdited: "Account plan updated successfully",
    MessageDelete: "Delete account?",
    MessageDeleteDetails:
      "Deleting your account would remove all responses, all chatbots. Please notice that this action is not reversible",
    DeleteConfirmInput: "Type DELETE to confirm",
    DeleteConfirmInputValue: "DELETE",
    DeleteConfirmInputValueError: "Type DELETE to confirm this action",
    AgreeWithPolicy: "By registering, you agree to our",
    TermsOfService: "Terms of Service",
    PrivacyPolicy: "Privacy Policy",
    And: "and",
    ChatbotPreview: "Chatbot Preview",
    Intelligent: "Intelligent",
    Day: "Day",
    MyChatbots: "My Chatbots",
    Change: "Change",
    ChatbotCreate: "Create chatbot",
    AvatarUploadExceedsSize: "Avatar exceeds upload limit of {0}",
    InvalidFormat: "Invalid file format",
    UploadFormatSizeAllowed: "Allowed {0} or {1}. Max size of {2}",
    ChatpageDescription: "Your Description to Chatpage",
    CreateChatPage: "Create Chatpage",
    CustomizePlanPro: "Customize Chatbot in Pro",
    ChatpagePlanPro: "Criate your Chatpage in PRO",
    MigrateNow: "Signup for Pro",
    AccessChatPage: "Go to your Chatpage",
    ChatpageNameInUse: "This name is already in use please choose another one",
    //Recursos dos Planos
    PlanResources: {
      ChatbotAdvanced: "1 Chatbot",
      ChatbotBusiness: "1 Chatbot",
      ChatbotPro: "1 Chatbot",
      ChatbotFree: "1 Chatbot",
      ConversationAdvanced: "Up to 2500 conversations",
      ConversationBusiness: "Up to 1000 conversations",
      ConversationPro: "Up to 300 conversations",
      ConversationFree: "Up to 100 conversations",
      BasicTemplates: "Basic Templates",
      ProTemplates: "PRO Templates",
      WhatsappCard: "WhatsApp Card",
      ChangeName: "Change Name",
      ChangePhoto: "Change Photo",
      ChangeColor: "Change Color",
      ChangeWelcomeMessage: "Change Welcome Message",
      WidgetSite: "Widget Site",
      Chatpage: "Chatpage",
    },
    ButtonTryPro: "Try 7 days free",
    Personal: "Personal",
    Business: "Business",
    Plans: "Plans",
    Customize: "Customize",
    Publish: "Publish",
    YourSubscription: "Your subscription",
    SubscriptionPro:
      "You're nearly there, complete the fields below to get access to the full suite of Duotalk features",
    TitlePagePro: "It’s time to turn PRO",
    SubtitlePro:
      "Duotalk PRO, Business and Advanced is a supercharged package that gets you even more out of your Bot. Customize colors, avatar, welcome messages and create your professional page with Chatpages. Upgrade or downgrade at any time.",
    ProPlan: "PRO Plan",
    BusinessPlan: "Business Plan",
    BusinessAdvancedPlan: "Business Advanced Plan",
    Tryal: "7 days free",
    ChooseTemplateCategory: "Choose a category",
    EmailSent: "Email sent",
    InstallScriptWordpress: {
      1: "Log in to the WordPress admin panel",
      2: "Go to Appearance > Theme Editor",
      3: "Under Theme Files (far right), find the Theme Footer (footer.php) and select it",
      4: "Paste the code of your Chatbot Duotalk before the </body> tag at bottom",
      5: "Click Update File to save changes",
    },
    InstallChatbot: "Install Chatbot",
    OpenChatpage: "Open Chatpage",
    ChoosedPlan: "Your current plan",
    ChooseThisPlan: "Select plan",
    Choose: "Choose",
    Crop: "Crop",
    AcceptedFormatAvatar: "Please choose a JPG or PNG file",
    SubscriptionSuccessfull:
      "🎉 You have been successfully subscribed to our plan {0}. Enjoy it!",
    CreateCardChatpage: "Add a new card",
    LinkTitle: "Enter a title for your link",
    SaveCardChatpage: "Save card",
    Title: "Title",
    TheCard: "The card",
    SaveUpdates: "Save updates",
    SubscribePlan: "Subscribe",
    SubscribePlanNow: "Subscribe now",
    Free: "free",
    AlreadyInThisPlan: "It is already your plan",
    CountryCode: "Contry code",
    InvalidPhone: "Invalid phone number",
    InformTheWhatsapps: "WhatsApp Numbers",
    PreviewChatbot: "Preview Chatbot",
    GetHelp: "Get Help",
    AppStore: "App store",
    Manage: "Manage",
    YourInstalledApps: "Installed apps",
    Category: "Category",
    Clear: "Clear",
    NoRecordsFounded: "No records founded",
    EnableSurveySending: "Enable the surveys sending on ",
    ConfigurationPage: "Configuration page",
    Filter: "Filter",
    Tags: "Tags",
    Install: "Install",
    AppInstalledSuccess: "App installed successfully",
    AppEditedSuccess: "App edited successfully",
    AppDeletedSuccess: "App deleted successfully",
    FillFieldsBellow: "Fill the fields below",
    Uninstall: "Uninstall",
    New: "New",
    App: "App",
    ConfirmRemove: "Are you sure that you want to permanently delete it? ",
    AppName: "App Name",
    SendOperatorIntegration: "Send operator id for integration?",
    AllTalks: "All talks",
    LeadsOnly: "Leads",
    Lead: "Lead",
    Talk: "Talk",
    HowToSetup: "How to Setup",
    SubscribeWithTrialDays: "Start trial",
    //livechat
    UnknowName: "Unknow",
    Department: "Department",
    Type: "Type",
    Inbox: "Inbox",
    Chatpage: "Chatpage",
    OptionsNotNull: "Insert at least one option",
    Options: "Options",
    SaveFlow: "Save flow",
    DeleteQuestionTitle: "Delete Question ",
    ConfirmDeleteQuestion:
      "Do you want to delete the question? This will permanently delete the question.",
    DeleteSubflowTitle: "Delete Question",
    ConfirmDeleteSubflow:
      "Do you want to delete the subflow? This will permanently delete the subflow and its child questions.",
    AddFlow: "Add Flow",
    Intention: "Intention",
    IntentionToolTip:
      "Intenções são objetivos do cliente e te ajudam a identificar qual departamento ele será atendido. Exemplo: Vendas, Suporte, Pós-Vendas.",
    MessageTag: "Message title",
    Entity: "Entity",
    EntityKey: "Key Entity",
    Entities: "Entities",
    KeyEntities: "Key Entities",
    BotMessage: "Bot message",
    NewMessage: "New Message",
    Contacts: "Contacts",
    EntityTooltip:
      "Entities are used to identify important parts of the conversation, such as names, product of interest, dates etc. To configure key entity, insert a dollar sign before the term, e.g.: $unit",
    // Inbox
    StartAttendance: "Start Attendance",
    InAttendance: "In Attendance",
    WaitingAttendance: "Waiting Attendance",
    AddConversation: "Add Conversation",
    AddConversationTitle: "New conversation",
    AddConversationSuccess: "New conversation created successfully",
    AddConversationAlreadyExists:
      "A active conversation already exists with this number",
    Confirm: "Confirm",
    TotalConversations: "Total conversations",
    TotalConversationsNumber: "Conversations",
    YourCompany: "Your Company",
    YourPlan: "Your Plan",
    StartConversation: "Start conversation",
    SearchContact: "Search here",
    AppStoreHistory: "Apps Syndication History",
    Channel: "Channel",
    StartDate: "Start Date",
    EndDate: "End Date",
    ConversationHistory: "Conversation History",
    ConversationClosed: "Conversation closed",
    LastConversations: "Last Conversations",
    VerifyDataAndTryAgain: "Verify the input data and try again",
    ongoing: "Ongoing",
    error: "Error",
    success: "Success",
    finished: "Finished",
    cancelled: "Cancelled",
    appHistoryReturnTitle: "App History Return Data",
    ReturnCode: "Return Code",
    YourName: "Your Name",
    YourEmail: "Your Email",
    AladyHaveAcc: "Already have an account?",
    LoginInstead: "Log In",
    CloseConversationSuccess: "Conversation closed successfully",
    ErrorOnCloseConversation:
      "An error occurred while closing the conversation. Please refresh the page and try again",
    ErrorOnForwardConversation:
      "An error occurred while forwarding the conversation. Please refresh the page and try again",
    CreateNewChatbot: "Create new",
    Next: "Next",
    AtDepartment: "at department",
    AndAttendant: "and attendant",
    CompanyEmployees: "Number of Employees",
    YouDontHaveAccess:
      "You don't have access to this feature, talk to your account administrator",
    Accept: "Accept",
    CloseConversationAccept: "Close",
    CloseConversation: "Close Conversation",
    SelectClosingReason: "Select the reason of closing",
    QuickMessages: "Quick Messages",
    AddQuickMessage: "Add message",
    AddTemplate: "Add Template",
    AutoMessages: "Log Messages",
    Message: "Message",
    ConfirmRemoveQuickMessage: "Are you sure you want to remove this message?",
    ConfirmRemoveTemplate: "Are you sure you want to remove this template?",
    EditVariablesTemplates: "Edit variables",
    ChatGptSuggestionTemplatePlaceholder:
      "What do you need? e.g., re-engage customers",
    ConfirmInactivateAutoMessageTitle: "Inactivate automatic message",
    ConfirmInactivateAutoMessage:
      "Are you sure you want to inactivate this message?",
    InactivateSuccessfully: "inactivate message successfully",
    ActivateSuccessfully: "activate message successfully",
    ConfirmActivateAutoMessageTitle: "Activate automatic message",
    ConfirmActivateAutoMessage:
      "Are you sure you want to activate this message?",
    FlowStart: "Flow Start",
    OperatorForward: "Operator Forward",
    CloseConversationNpsSurvey: "Close conversation with Nps Survey",
    CloseConversationCsatSurvey: "Close conversation with Csat Survey",
    InvalidCoupon: "Coupon code is not valid",
    WhatsAppCompliantQRCode:
      "Before continue authenticating, you must agree to WhatsApp number ban on",
    WhatsAppCompliantQRCodeTerm: "Acceptable Use Of Our Services",
    WhatsAppCompliantQRCodeLink:
      "https://www.whatsapp.com/legal/terms-of-service?lang=en#terms-of-service-acceptable-use-of-our-services",
    NumberDoesNotExist: "It was not possible, this number does not exist",
    SatisfactionSurveys: "Satisfaction Surveys",
    Feedback: "Feedback",
    ViewInInbox: "View in inbox",
    CustomerSatisfaction: "Customer Satisfaction",
    TypeOfResearch: "Type of research",
    SearchName: "Search name",
    FilterDates: [
      "All",
      "This week",
      "This month",
      "This year",
      "Last week",
      "Last month",
      "Last year",
    ],
    NoContactFounded: "No contact founded",
    Operators: "Operators",
    WhatsappNumber: "WhatsApp Number",
    SmsNumber: "SMS Number",
    Address: "Address",
    Description: "Description",
    Vertical: "Vertical",
    Websites: "Websites",
    Website: "Website",
    ProfilePhoto: "Profile Photo",
    WppApiVerticalAutomotive: "Automotive",
    WppApiVerticalBeautySpaAndSalon: "Beauty, Spa and Salon",
    WppApiVerticalClothingAndApparel: "Clothing and Apparel",
    WppApiVerticalEducation: "Education",
    WppApiVerticalEntertainment: "Entertainment",
    WppApiVerticalEventPlanningAndService: "Event Planning and Service",
    WppApiVerticalFinAnceandBanking: "Finance and Banking",
    WppApiVerticalFoodAndGrocery: "Food and Grocery",
    WppApiVerticalPublicService: "Public Service",
    WppApiVerticalHotelAndLodging: "Hotel and Lodging",
    WppApiVerticalMedicalAndHealth: "Medical and Health",
    WppApiVerticalNonprofit: "Non-profit",
    WppApiVerticalProfessionalServices: "Professional Services",
    WppApiVerticalShoppingAndRetail: "Shopping and Retail",
    WppApiVerticalTravelAndTransportation: "Travel and Transportation",
    WppApiVerticalRestaurant: "Restaurant",
    WppApiVerticalOther: "Other",
    About: "About",
    SuccessWhatsappEditInfo: "WhatsApp info saved",
    SuccessWhatsappEditPic: "WhatsApp picture saved",
    WppApiTemplateCategory1:
      "Deixe os clientes cientes sobre atualizações em suas contas em algum sistema",
    WppApiTemplateCategory2:
      "Mensagem automática para quando o seu negócio não está online para disponível para uma resposta no momento",
    WppApiTemplateCategory3: "Mensagens sobre finanças pessoais",
    WppApiTemplateCategory4: "Informações sobre atualizações em tickets",
    WppApiTemplateCategory5:
      "Envio de informações importantes ou novidades para os clientes",
    WppApiTemplateCategory6:
      "Resposta para perguntas, preocupações ou feedbacks dos clientes",
    WppApiTemplateCategory7:
      "Envio de confirmações, lembretes ou outras atualizações sobre alguma reserva do cliente",
    WppApiTemplateCategory8: "Envio de informações sobre viagens",
    WppApiTemplateCategory9:
      "Envio de confirmações, lembretes ou outras atualização sobre o agendamento do cliente",
    WppApiTemplateCategory10:
      "Envio de informações sobre questões de pagamento",
    WppApiTemplateCategory11: "Envio de informações sobre entrega de produtos",
    WppApiTemplateCategory12:
      "Send account updates, order updates, alerts, and more to share important information",
    WppApiTemplateCategory13:
      "Send promotional offers, product announcements, and more to increase awareness and engagement",
    WppApiTemplateCategory14:
      "Send codes that allow your customers to access their accounts",
    WppApiTemplateFit: "Choose a category?",
    ChooseOneChatbot: "Choose a chatbot",
    SubmitTemplate: "Submit Template",
    Wpp24hWindowError:
      "Message failed to send because more than 24 hours have passed since the customer last replied to this number",
    NoIntention: "others",
    NewConversation: "New conversation",
    Ago: "ago",
    Hours: "hours",
    Invitation: "Invitation",
    ResubmitTemplate: "Resubmit Template",
    Status: "Status",
    Add: "Add",
    AddedSuccessfuly: "Added successfuly",
    ConfirmDeleteIntentionTitle: "Deletear intenção",
    ConfirmDeleteIntention: "Tem certeza que deseja excluir essa intenção?",
    No: "Não",
    Yes: "Sim",
    Now: "Now",
    Intentions: "Intentions",
    JsonPathText1:
      "JSONPath is a query language for JSON. You can use a path (or JSONPath expression) to retrieve the value of certain property from a JSON response (you'll usually get a response in this format from an API request).",
    JsonPathText3: "Some examples of JSONPath expressions are as follows:",
    JsonPathText2:
      "The leading $ represents the root object, dot (.) is used for accessing properties present inside the JSON, the value inside brackets ([0]) represents the array index if the property contains an array.",
    Test: "Test",
    EditedSuccessfully: "Edited successfully",
    UserRole: "Role",
    Manager: "Manager",
    Operator: "Operator",
    UnknownName: "Unknown",
    AlreadyInAttendanceOrClosed: "Conversation taken or already closed",
    FoundedAlreadyInAttendance: "Conversation founded in attendance already",
    ErrorOnSendFileMessage: "Error on file upload, please try again",
    GenericErrorPleaseReportTitle: "Oops! Something went wrong!",
    GenericErrorPleaseReportMessage:
      "Help us improve your experience by sending an error report",
    YoureOffline: "Computer not connected",
    NetworkIssues:
      "Make sure your computer has an active Internet connection. ",
    System: "System",
    DownloadCSV: "Download CSV",
    DownloadXLSX: "Download XLSX",
    WebsocketConflict:
      'Duotalk is open on another window or tab. Click "User here" to use Duotalk in this window',
    UseHere: "User here",
    Close: "Close",
    Warning: "Warning",
    AttendanceByChannel: "Attendance by Channel",
    ConversationByChannel: "Conversations by Channel",
    NotPossibleToStartConversation:
      "It was not possible to start the conversation. Try again",
    NoneEntity: "Without entities only",
    others: "Others",
    Campaigns: "Campaigns",
    Campaign: "Campaign",
    NewCampaign: "New Campaign",
    CampaignName: "Campaign Name",
    CampaignDescription: "Campaign Description",
    ChooseTemplate: "Choose a Template",
    CampaignData: "Campaign Data",
    ContactsData: "Contact Data",
    ReviewAndSend: "Review and Send",
    SubmitCampaign: "Submit & Trigger Campaign",
    Back: "Back",
    TotalContacts: "Contacts No.",
    Errors: "Errors",
    CampaignAgreeBeforeContinueCSV: "Before continue, you must agree to terms.",
    CampaignCSVTerms:
      "In the General Data Protection Act (LGPD) and General Data Protection Regulation (GDPR) it is necessary to register a Legal Basis associated with the collection and use of data from a lead received. By continuing you guarantee that you have the Consent, Legitimate Interest or Preexisting Contract as well as the OPT-IN for communication of each of these contacts.",
    Send: "Enviar",
    CampaignUploadCSV: "Upload CSV",
    atTime: "at",
    File: "File",
    History: "History",
    Answer: "Answer",
    Answers: "Answers",
    Waiting: "Waiting",
    Finished: "Finished",
    Executing: "Executing",
    FileIsTooLarge: "The files to this channel must be less than {0}MB",
    FileTypeXNotAllowdedChannel:
      "Files' {0} type is not allowded in this channel",
    ChannelDontAcceptMedia: "This channel does not allow send files",
    ErrorOnLoadingConversations:
      "Error on loading conversations. Try again later",
    ErrorAttendanceAnotherOperator:
      "This conversation belongs to another operator",
    ErrorAttendanceAnotherOperatorOrNotFounded:
      "Conversation closed or with another operador",
    UnexpectedErrorLoadConversationMessages:
      "Error while loading messages, please try again",
    LinkRemovedSecured: "Private link removed",
    AiGeneralSugestionLabel:
      "What would you like our Artificial Intelligence to know to generate better suggestions?",
    AiGeneralSugestionPlaceholder:
      "Examples: where is your business? what do you work with? What are the hours of operation Company size? and other information you deem important.",
    AiGeneralAnswerLabel: "What style of response do you want?",
    AiGeneralAnswerPlaceholder:
      "Examples: We are informal but we like short and objective answers.",
    AiPromptTemplateSugestionLabel: "Prompt for template suggestion",
    AiPromptTemplateSugestionPlaceholder:
      "Create a WhatsApp message with a maximum of 200 characters",
    AiPromptTemplateSugestionLegend:
      "The prompt above will be used as the basis for all template suggestions. You can change it whenever you want.",
    AiPromptAnswerSugestionLabel: "Prompt for answer suggestion",
    AiPromptAnswerSugestionPlaceholder:
      "Suggest an answer to the customer's last question in the text below, in a summarized and informal format",
    AiPromptAnswerSugestionLegend:
      "The prompt above will be used as the basis for all template suggestions. You can change it whenever you want.",
    AiPromptChatSummaryLabel: "Prompt for chat summary",
    AiPromptChatSummaryLegend:
      "The prompt above will be used as a basis for resuming your Inbox conversations. You can change it whenever you want.",
    AiPromptSentimentAnalysisLabel: "Prompt for sentiment analysis",
    AiPromptSentimentAnalysisLegend:
      "The prompt above will be used as a basis for sentiment analysis. You can change it whenever you want.",
    AiPromptSentimentAnalysisPlacehold:
      "Write a message to analyze the sentiment",
    SignOperator: "Operator signature",
    AutomaticConversationDistribution: "Automatic conversation distribution",
    Downtime: "Downtime",
    MinMaxDays: "Minimum of 1 day, and maximum of 90 days.",
    ReasonsForClosure: "Reasons for closure",
    ServiceAlerts: "Service Alerts",
    EditStatus: "Edit Status",
    AddNewReason: "Add new reason",
    ReasonNameCannotBeEmpty: "Reason name cannot be empty",
    ReasonWithThisNameAlreadyExists: "A reason with this name already exists",
    AddReason: "Add reason",
    NumberOfReasonsReached: "Limit number of reasons reached.",
    AddNew: "Add new",
    RemoveReason: "Remove Reason",
    AreYouSureWantRemoveReason: "Are you sure you want to remove the reason",
    ChooseDate: "Choose Date",
    FilterByDate: "Filter by date",
    SavedSuccessfuly: "Saved successfuly",
    SmartSecondContact: "Smart second contact",
    BlockContactTab: "Block Contact Tab for Operator",
    BlockWaitingServiceTab:
      "Block ‘Waiting for service’ queue for Operator",
    BlockContactCreation: "Block Conversation Creation for Operator",
    "Duotalk IA": "Duotalk AI",
    ConfirmEdit: "Are you sure you want to edit this?",
    Templates: "Templates",
    InfoDash:
      "The data presented refers to D-1, that is, today's data will only be presented the following day.",
    CSVStructureError:
      "Verify if the CSV is in accordance with the example provided on the previous page.",
    Finish: "Finish",
    EmptyTable: "Empty Table",
    Total: "Total",
    Template: "Template",
    InboxNoCampaignAvailableMsgP1: "Your",
    InboxNoCampaignAvailableMsgP2: "interactive messages",
    InboxNoCampaignAvailableMsgP3: "through the",
    InboxNoCampaignAvailableMsgP4: "Campaign module",
    InboxNoCampaignAvailableMsgP5:
      "will be displayed here until the contact interacts with the campaign message.",
    NoConversationFounded: "No conversation founded",
    LoadMoreConversations: "Load more",
    AllConversationsLoaded: "All conversations have been loaded",
    SMSContentLabel: "Enter SMS content",
    Clicks: "Clicks",
    CampaignType: "Campaign Type",
    InternalServerError:
      "We apologize, but our server encountered an internal error while processing your request. Please try again later. Thank you for your patience and understanding. If the problem persists, feel free to contact our support team at [suporte@duotalk.io] for further assistance.",
    TryAgain: "Try Again",
    PageLoginWithFacebook: "Sign in with Facebook",
    PageLoginWithFacebookPermissionsFail:
      "Login failed, integration requires all requested permissions",
    WriteMessage: "Write a message",
    Enter: "Enter",
    AddAttendantsName: "to add the attendant's name and",
    ContactName: "for the contact name",
    TemplateHasCustomizableVariables:
      "This template has customizable variables.",
    HowItWorks: "How it works?",
    HowWouldLikeToReplace:
      "How would you like to replace it for this shipment?",
    PreviewTemplate: "Preview Template",
    Hide: "Hide",
    Departments: "Departments",
    SomeConversationsMayNotAppear:
      "Some conversations may not appear until the desync process is complete",
    DropFilesHere: "Drop the files here.",
    Postpone: "Postpone",
    PostponeTo: "Postpone To",
    Attendant: "Attendant",
    ForwardConversation: "Forward conversation",
    Forward: "Forward",
    Respond: "Respond",
    BackToStart: "Back to start",
    StartService: "Start service",
    Redo: "Redo",
    SuggestAnswer: "Suggest answer",
    SeeContactsAndStartConversation: "View contacts and initiate service",
    EnterName: "Enter name",
    AddPhone: "Add phone",
    Transcribe: "Transcribe",
    Transcript: "Transcript",
    SeeFullTranscript: "see full transcript",
    TestChatbot: "Test chatbot",
    TypeYourMessage: "Type your message",
    GenerateSummary: "Generate summary",
    RedoSummary: "Redo summary",
    SummaryOfResearch: "Summary of research",
    GeneratingQuickSummary:
      "How about generating a quick summary of all your answers?",
    inbox: {
      emptyMessageInput: "The message field is empty, please fill it in",
      emptyMessage: "Empty field",
    },
    ComputerOffline: "Computer not connected",
    ComputerOfflineExplain:
      "Make sure your computer has an active internet connection.",
    Dialog360SignupErrorAPIGen: "An issue was encountered during the signup process. Please try again later",
    InboxLoadSize: "Inbox conversations quantity loaded per request",
    InboxLoadSizeInfo: "Number of conversations loaded upon entering the Inbox and on clicking 'Load More'",
    InboxLoadMinMax: "Minimum 20 and maximum 1000",
    RemoveOperatorQueueAnswerLabel: "Are you sure you want to remove this user from the automatic distribution queue?",
    AddOperatorQueueAnswerLabel: "Are you sure you want to add this user to the automatic distribution queue?",
    RemoveOperatorQueueAnswerTitle: "Remove user from queue",
    AddOperatorQueueAnswerTitle: "Add user to queue",
    WebhookCall: "Webhook Call",
    OptionalParameters: "Optional parameters",
    AIUsageExample: "Identification example",
    WebsiteInstalation: "Instalar no site",

    Channels: "Channels",
    ChatbotHintInstWJavaScript: "Install on site with JavaScript",
    ChatbotHintCopyTheCode: "Copy the code and paste before the closing tag",
    ChatbotHintOfYourSite: "of your site",
    ChatbotHintInstallOnWordpress: "Install on Wordpress",
    ChatbotHintSendToTheDeveloper: "Send to the developer",
    ChatbotHintIfYouPrefere: "If you prefer, send this code to someone who can install it on your site. All the instruction will be sent to them",
    SendCode: "Send Code",
    OtherPlatforms: "Other Platforms",
    ChatbotHintOtherPlatforms: "Your chatbot can be integrated with other platforms",
    CheckOurAppStore: "Check our App store for available options",
    GoToAppStore: "Go to App store",
    Done: "Done",
    WhatsappOptions: "WhatsApp Options",
    NotifyOptions: "Receive notifications by email or phone. Select the options you want to be notified about.",
    NotifyMessage: "I want to be notified whenever a new message is sent",
    NotifyTransfer: "I want to be notified whenever a transfer is made",
    NotifySurveyResponse: "I want to be notified whenever a new satisfaction survey response is made",
    InboxConversationsSyncAlert: "Some conversations may not appear until the synchronization process is complete.",
    SaveFlowAndReturn: "Save and Return",
  },
  pt_BR: {
    ServiceQueue: "Fila de atendimento",
    campaigns: {
      lastTableUpdate: 'Última atualização'
    },
    Note: "Nota",
    Notes: "Notas",
    QuickMessageCreated: "Mensagem rápida criada com sucesso!",
    Media: "Mídia",
    Opening: "Abertura",
    Closing: "Fechamento",
    triggerIntegrationLabelOpening: "Abertura de conversa",
    triggerIntegrationLabelClosing: "Fechamento de conversa",
    triggerIntegrationLabelCampaign: "1ª resposta de campanha",
    triggerIntegrationLabelAll: "Todos",
    Tomorrow: "Amanhã",
    Today: "Hoje",
    Year: "Ano",
    DoYouWantLogout: "Deseja efetuar logout",
    SyncTemplates: "Sincronizar",
    SyncingTemplates: "Sincronizando",
    Photo: "Foto",
    Remove: "Remover",
    Country: "País",
    Language: "Idioma",
    Chatbots: "Chatbots",
    Reset: "Resetar",
    Save: "Salvar",
    Cancel: "Cancelar",
    FirstName: "Nome",
    LastName: "Sobrenome",
    Phone: "Telefone",
    Dashboard: "Dashboard",
    eCommerce: "eCommerce",
    Apps: "Apps",
    Todo: "Todo",
    Email: "Email",
    Calendar: "Calendário",
    FullCalendar: "Calendário Completo",
    SimpleCalendar: "Calendário Simples",
    Shop: "Compras",
    ItemDetails: "Detalhes do item",
    Cart: "Carrinho",
    WishList: "Lista de Desejos",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Lista de dados",
    ListView: "Exibição de lista",
    ThumbView: "ThumbView",
    Grid: "Grade",
    Vuexy: "duotalk",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Cores",
    Card: "Cartão",
    Basic: "Basic",
    Statistics: "Estatisticas",
    Analytics: "Analytics",
    CardActions: "Ações",
    CardColors: "Cores",
    Table: "Mesa",
    agGridTable: "Tabela AgGrid",
    Components: "Componentes",
    Alert: "Alerta",
    Avatar: "Avatar",
    Badge: "Crachá",
    Breadcrumb: "Breadcrumb",
    Button: "Botão",
    Buttons: "Botões",
    ButtonGroup: "Grupo de botões",
    Body: "Body",
    Header: "Header",
    Chip: "Lasca",
    Collapse: "Colapso",
    Dialogs: "Diálogos",
    Divider: "Divisor",
    DropDown: "Dropdown",
    List: "Lista",
    Loading: "Carregando",
    Navbar: "Navbar",
    Notifications: "Notificações",
    Pagination: "Paginação",
    Popup: "Popup",
    Progress: "Progresso",
    Sidebar: "Barra Lateral",
    Slider: "Controle Deslizante",
    Tabs: "Guias",
    Tooltip: "Tooltip",
    Upload: "Enviar",
    FormsAndTable: "Formulários e tabela",
    FormElements: "Elementos do formulário",
    Select: "Selecione",
    SelectAll: "Selecionar todos",
    Switch: "Interruptor",
    Checkbox: "Caixa de seleção",
    Radio: "Rádio",
    Input: "Entrada",
    NumberInput: "Entrada Numérica",
    Textarea: "Textarea",
    FormLayouts: "Layouts de Formulário",
    FormWizard: "Assistente de Formulário",
    FormValidation: "Validação de Formulário",
    FormInputGroup: "Grupo de Formulário",
    Pages: "Páginas",
    Authentication: "Autenticação",
    Login: "Entrar",
    Register: "Registrar",
    ForgotPassword: "Esqueceu sua senha",
    ResetPassword: "Redefinir Senha",
    LockScreen: "Tela de bloqueio",
    Miscellaneous: "Diversos",
    ComingSoon: "Em breve",
    Error: "Erro",
    404: "404",
    500: "500",
    Title404: "404 - Página não encontrada",
    Description404:
      "Parece que o conteúdo que você tentou acessar não está disponível",
    Button404: "Acessar Conta",
    NotAuthorized: "Não autorizado",
    Maintenance: "Manutenção",
    Profile: "Perfil",
    User: "Usuário",
    View: "Visualizar",
    Edit: "Editar",
    UserSettings: "Configurações do Usuário",
    ChatbotSettings: "Configurações do Chatbot",
    ChatbotBehavior: "Configurar Funcionamento",
    Invoice: "Fatura",
    FAQ: "Perguntas frequentes",
    SearchUsers: "Procurar usuários",
    Search: "Procurar",
    KnowledgeBase: "Base de Conhecimento",
    ChartsAndMaps: "Gráficos E Mapas",
    Charts: "Gráficos",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Mapa",
    Extensions: "Extensão",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Arraste e solte",
    Datepicker: "Datepicker",
    DatetimePicker: "Seletor de data e hora",
    AccessControl: "Controle de acesso",
    I18n: "I18n",
    Carousel: "Carrossel",
    Clipboard: "Clipboard",
    ContextMenu: "Menu contextual",
    StarRatings: "Classificações por estrelas",
    Autocomplete: "autocompletar",
    Tree: "Árvore",
    // "Import/Export": 'Importar/Exportar',
    Import: "Importar",
    Export: "Exportar",
    ExportSelected: "Exportar Selecionado",
    Others: "Outros",
    MenuLevels: "Níveis de Menu",
    MenuLevel2p1: "Nível de menu 2.1",
    MenuLevel2p2: "Nível de menu 2.2",
    MenuLevel3p1: "Nível de menu 3.1",
    MenuLevel3p2: "Nível de menu 3.2",
    DisabledMenu: "Menu desativado",
    Support: "Apoio, suporte",
    Documentation: "Documentação",
    RaiseSupport: "Suporte",
    demoTitle: "Título",
    demoText: "",

    CreatedChatbots: "Chatbots",
    Inactivate: "Desativar",
    Activate: "Ativar",
    Delete: "Excluir",
    Active: "Ativo",
    Inactive: "Inativo",
    SnippetCode: "Código de Implementação",
    SnippetInstructions:
      "Copie o código abaixo e cole no seu site, antes do fechamento do </head>",
    IntelligentX: "{0} inteligente",
    CopyCode: "Copiar código",
    Copy: "Copiar",
    Copied: "Copiado",
    CreateYourFirstChatbot: "Crie seu primeiro Chatbot escolhendo um template",
    TemplatesReadyToUse: "Templates prontos para uso",
    All: "Todos",
    AllChatbots: "Todos os Chatbots",
    UnexpectedError: "Erro inesperado",
    UnexpectedErrorLoadX: "Ocorreu um erro inesperado ao carregar {0}",
    ConfirmDeleteOfX: "Confirmar Exclusão de {0}",
    ConfirmDeletedPermanentlyX: "{0} será excluído permanentemente",
    TheChatbot: "O Chatbot",
    Deleted: "Excluído",
    DeletedSuccessfuly: "foi excluído com sucesso",
    UnexpectedErrorDeleteX: "Ocorreu um erro inesperado ao deletar {0}",
    ChatbotName: "Nome do Chatbot",
    ChooseChatbot: "Qual chatbot deseja utilizar?",
    WebsiteToInstall: "Site que será instalado o Chatbot",
    EmailToReceiveLeads: "Email que receberá os leads",
    InformAWhatsapp: "Cadastre um WhatsApp",
    InformTheWhatsapps: "Números WhatsApp",
    CreateChatbot: "Criar Chatbot",
    ChatbotExample: "Exemplo do seu Chatbot",
    AllowedAvatar: "Allowed JPG, GIF or PNG. Max size of 800kB",
    FirstMessage: "Mensagem de boas-vindas",
    WidgetColor: "Cor do Chatbot",
    WidgetCustomization: "Customização",
    SocialNetwork: "Redes Sociais",
    ChatbotGreeting: "Saudação",
    WidgetPosition: "Posição do Chatbot",
    ChatbotCreatedSuccessfuly: "Chatbot {0} criado com sucesso",
    ChatbotEditedSuccessfuly: "Chatbot editado com sucesso",
    FollowTheInstructionsOnScreen: "Siga as instruções na tela",
    EditChatbot: "Editar Chatbot",
    EditFlow: "Editar Fluxo",
    EditChatpage: "Editar Chatpage",
    ChatpageTitle: "Título da Chatpage",
    ChatpagePreview: "Exemplo da Chatpage",
    MessageDescription: "Mensagem da descrição",
    MetaTitle: "Meta Título (SEO)",
    metaDescription: "Meta Descrição (SEO)",
    ChatpageLink: "Link da Chatpage",
    Of: "de",
    Complete: "Completo",
    Incomplete: "Incompleto",
    Hour: "Hora",
    Integration: "Integração",
    Name: "Nome",
    name: "Nome",
    Chatbot: "Chatbot",
    Home: "Home",
    Leads: "Leads",
    CurrentPassword: "Senha atual",
    NewPassword: "Nova senha",
    ConversationsLimit: "Limite de atendimentos simultâneos",
    ConfirmNewPassword: "Confirmar nova senha",
    PasswordChanged: "Senha alterada com sucesso",
    InformationChanged: "Dados alterados com sucesso",
    YourProfile: "Seu Perfil",
    YourAccount: "Sua Conta",
    YourTeam: "Seu Time",
    Team: "Usuários",
    LabelDistributionQueue: "Distribuição automática",
    Logout: "Sair",
    LogoutSuccess: "Deslogado com sucesso",
    LoginWithFacebook: "O chatbot foi conectado ao Facebook com sucesso",
    LoginWithInstagram: "O chatbot foi conectado ao Instagram com sucesso",
    RemindMe: "Lembre-me",
    SignUp: "Criar Conta",
    Create: "Criar",
    Success: "Sucesso",
    General: "Geral",
    ChangePassword: "Trocar Senha",
    Settings: "Configurações",
    Filters: "Filtros",
    NotInformed: "Não Informado",
    ResetPasswordMessage:
      "Um email foi enviado com instruções para acessar sua conta",
    LoginWelcomeBack: "Bem-vindo de volta, acesse sua conta",
    ResetPasswordMessageWindow:
      "Digite seu endereço de e-mail no campo abaixo e enviaremos as instruções de como resetar sua senha",
    GoBack: "Voltar",
    InvalidEmail: "Informe um email válido",
    Continue: "Continuar",
    AccReadyMessage: "Pronto, agora acesse sua conta",
    PersonalInformation: "Dados pessoais",
    TellYourName: "Primeiro queremos saber seu nome",
    TellYourEmail: "Agora seu e-mail",
    CreateAPassword: "Crie uma senha para sua conta",
    ConfirmPassword: "Confirme a senha informada",
    AccInformation: "Dados da Conta",
    CompanyName: "Nome da Empresa",
    TellCompanyName: "Qual o nome da sua empresa / site",
    TellCompanyIndustry: "Qual o ramo da sua empresa / site",
    ChoosePlan: "Escolha um plano",
    ChangeMyPlan: "Alterar meu Plano",
    ChoosePlanPremium: "Altere para o Plano Premium",
    ChoosePlanPro: "Altere para o Plano PRO",
    DontSupportPlanChatbotPremium:
      "Para criar Chatbot com Template PRO você deve alterar para o Plano PRO",
    GetFree30Days: "Faça o Upgrade agora e ganhe 30 dias grátis",
    NoChoosePlan: "Não, desejo continuar com ",
    DiscontCoupon: "Cupom de desconto",
    FormValidationGeneric: "Preencha os campos corretamente antes de continuar",
    CopiedToClipboard: "Copiado para área de transferência",
    VerificationCode: "Código de verificação",
    VerifyUserMessage:
      "Insira o código enviado para seu email para continuar. Não recebeu o código?",
    ResendVerificationCode: "Reenviar Código",
    Verify: "Verificar",
    VerifyUserEmailMessage: "Email com o código de verificação enviado",
    RegisteredSuccessfully: "Seja bem vindo a Duotalk!",
    HiHowAreYou: "Olá, como vai?",
    Welcome: "Bem-vindo(a)",
    ToDuotalk: "à Duotalk",
    CreateNowChatbot: "agora você já pode criar seu Chatbot.",
    Chats: "Chats",
    TrialDaysLeft: "Seu teste finaliza em {0} {1}",
    Days: "Dias",
    YourPlanIs: "Seu plano é",
    Has: "Tem",
    OnTotalOf: "No total de",
    Talks: "Conversas",
    With: "Com",
    Users: "Usuários",
    FreeTierTimeLeft: "para finalizar seu período gratuito",
    ThereIs: "Restam",
    Chat: "Chat",
    YourDuoTalkAcc: "Sua conta no Duotalk",
    Account: "Conta",
    UserList: "Lista de Usuários",
    AccountEdit: "Editar Conta",
    CompanyIndustry: "Ramo da Empresa",
    Plan: "Plano",
    Price: "Preço",
    BillingFrequency: "Periodicidade",
    Month: "Mês",
    Months: "Meses",
    Monthly: "Mensal",
    ChangePlan: "Mudar Plano",
    Billing: "Financeiro",
    BillingHistory: "Histórico de Pagamento",
    NextPayment: "Próximo Pagamento",
    CreatedAt: "Criado em",
    CreatedBy: "Criado por",
    Receipt: "Nota Fiscal",
    Date: "Data",
    CustomDate: "Data Personalizada",
    Pending: "Pendente",
    Reconnecting: "Reconectando",
    Each: "A cada",
    NewUser: "Novo Usuário",
    AddUser: "Adicionar Usuário",
    AddButton: "Adicionar Botão",
    InviteUser:
      "Informe o email para convidar ou adicionar um usuário no acesso a esta conta",
    UserInvitedSuccessfully: "Usuário convidado/adicionado com sucesso",
    CreateAccount: "Criar conta Duotalk",
    CreateAccountMessage:
      "Preencha os campos a seguir para completar sua conta",
    Password: "Senha",
    ConfirmRemoveUserAccess: "Deseja Continuar?",
    ConfirmRemoveUserAccessMessage:
      "Tem certeza que deseja excluir {0}?",
    AccEdited: "Conta editada com sucesso",
    AccCreated: "Conta criada com sucesso",
    AccPlanEdited: "Plano editado com sucesso",
    MessageDelete: "Deseja excluir sua conta?",
    MessageDeleteDetails:
      "Excluindo essa conta os chats e leads tambem serao deletados. Essa ação não é reversível. Tem certeza que deseja continuar?",
    DeleteConfirmInput: "Digite EXCLUIR para confirmar",
    DeleteConfirmInputValue: "EXCLUIR",
    DeleteConfirmInputValueError: "Digite EXCLUIR para confirmar essa ação",
    AgreeWithPolicy: "Ao continuar, você concorda com os",
    TermsOfService: "Termos de Uso",
    PrivacyPolicy: "Política de Privacidade",
    And: "e",
    ChatbotPreview: "Exemplo Chatbot",
    Intelligent: "Inteligente",
    Day: "Dia",
    MyChatbots: "Ver Chatbots",
    Change: "Alterar",
    ChatbotCreate: "Criar chatbot",
    AvatarUploadExceedsSize: "Arquivo do avatar excedeu o tamanho de {0}",
    InvalidFormat: "Formato de arquivo inválido",
    UploadFormatSizeAllowed:
      "Permitido formato {0} ou {1}. Tamanho máximo de {2}",
    ChatpageDescription: "Sua descrição para a Chatpage",
    CreateChatPage: "Criar Chatpage",
    CustomizePlanPro: "Customize o Chatbot no Plano Pro",
    ChatpagePlanPro: "Crie sua Chatpage no Plano PRO",
    MigrateNow: "Ir para o PRO",
    AccessChatPage: "Acesse sua chatpage",
    ChatpageNameInUse: "Este nome já está em uso, escolha outro",
    //Recursos dos Planos
    PlanResources: {
      ChatbotAdvanced: "1 Chatbot ativo",
      ChatbotBusiness: "1 Chatbot ativo",
      ChatbotPro: "1 Chatbot ativo",
      ChatbotFree: "1 Chatbot",
      ConversationAdvanced: "Até 2500 Conversas",
      ConversationBusiness: "Até 1000 Conversas",
      ConversationPro: "Até 300 Conversas",
      ConversationFree: "Até 100 Conversas",
      BasicTemplates: "Templates Básicos",
      ProTemplates: "Templates PRO",
      WhatsappCard: "WhatsApp Card",
      ChangeName: "Alterar Nome",
      ChangePhoto: "Alterar Foto",
      ChangeColor: "Alterar Cor",
      ChangeWelcomeMessage: "Alterar Mensagem de Boas Vindas",
      WidgetSite: "Widget Site",
      Chatpage: "Chatpage",
    },
    ButtonTryPro: "Testar 7 dias grátis",
    Personal: "Individual",
    Business: "Empresarial",
    Plans: "Planos",
    Customize: "Customizar",
    Publish: "Publicar",
    YourSubscription: "Sua Assinatura",
    SubscriptionPro:
      "Você está quase lá, preencha os campos abaixo com seus dados financeiros para obter acesso ao conjunto completo de recursos do Duotalk",
    TitlePagePro: "É hora de escolher um plano",
    SubtitlePro:
      "Duotalk PRO, Business e Advanced são pacotes de vantagens para você aproveitar mais seu Bot. Customize cores, fotos, mensagens e crie sua página profissional através da Chatpages. Faça upgrade ou downgrade do plano a qualquer momento.",
    ProPlan: "Plano PRO",
    BusinessPlan: "Plano Business",
    BusinessAdvancedPlan: "Plano Advanced Business",
    Tryal: "7 dias grátis",
    EmailSent: "Email enviado",
    ChooseTemplateCategory: "Escolha uma categoria",
    InstallScriptWordpress: {
      1: "Entre no painel de administração do WordPress do seu site",
      2: "Acesse o menu Aparência > Editor de temas",
      3: "Em Arquivos de tema, localize o rodapé do tema (footer.php) e selecione-o",
      4: "Cole o código do seu Chatbot Duotalk antes da tag </body> na parte inferior",
      5: "Clique em Atualizar arquivo para salvar as alterações",
    },
    InstallChatbot: "Instalar Chatbot",
    OpenChatpage: "Abrir Chatpage",
    ChoosedPlan: "Plano atual",
    ChooseThisPlan: "Escolher este plano",
    Choose: "Escolher",
    Crop: "Cortar",
    AcceptedFormatAvatar: "Por favor escolha um arquivo JPG ou PNG",
    SubscriptionSuccessfull:
      "🎉 Parabéns, você acaba de ativar seu acesso a Duotalk {0}, aproveite uma ampla variedade de recursos que transformarão seu atendimento.",
    CreateCardChatpage: "Adicionar novo card",
    LinkTitle: "Digite um título para o seu link",
    SaveCardChatpage: "Salvar card",
    Title: "Título",
    TheCard: "O card",
    SaveUpdates: "Salvar atualizações",
    SubscribePlan: "Contratar",
    SubscribePlanNow: "Contratar agora",
    Free: "grátis",
    AlreadyInThisPlan: "Este já é seu plano",
    CountryCode: "Código do país",
    InvalidPhone: "Informe um telefone válido",
    PreviewChatbot: "Ver Exemplo",
    GetHelp: "Central de Ajuda",
    AppStore: "App store",
    Manage: "Administrar",
    YourInstalledApps: "Apps instalados",
    Category: "Categoria",
    Clear: "Limpar",
    NoRecordsFounded: "Nenhum dado encontrado",
    EnableSurveySending: "Ative o envio das pesquisas de satisfação na",
    ConfigurationPage: "Página de configurações",
    ErrorSavingConfiguration: "Erro ao salvar configurações.",
    Filter: "Filtros",
    Tags: "Tags",
    Install: "Instalar",
    AppInstalledSuccess: "App instalado com sucesso",
    AppEditedSuccess: "App editado com sucesso",
    AppDeletedSuccess: "App deletado com sucesso",
    FillFieldsBellow: "Preencha os campos a seguir",
    Uninstall: "Desinstalar",
    New: "Novo",
    App: "App",
    ConfirmRemove: "Tem certeza que deseja continuar?",
    AppName: "Nome do App",
    SendOperatorIntegration: "Enviar operador id na integração?",
    AllTalks: "Todas as Conversas",
    LeadsOnly: "Somente Leads",
    Lead: "Lead",
    Talk: "Conversa",
    HowToSetup: "Como configurar",
    SubscribeWithTrialDays: "Iniciar teste grátis",
    //livechat
    UnknowName: "Desconhecido",
    Department: "Departamento",
    Type: "Tipo",
    Inbox: "Inbox",
    Chatpage: "Chatpage",
    OptionsNotNull: "Insira pelo menos uma opção",
    Options: "Opções",
    SaveFlow: "Salvar fluxo",
    DeleteQuestionTitle: "Deletar Pergunta",
    ConfirmDeleteQuestion:
      "Deseja deletar a pergunta? Isso irá excluir permanentemente a pergunta.",
    DeleteSubflowTitle: "Deletar Subfluxo",
    ConfirmDeleteSubflow:
      "Deseja deletar o subfluxo? Isso irá excluir permanentemente o subfluxo e suas perguntas filhas.",
    AddFlow: "Adicionar Fluxo",
    Intention: "Intenção",
    IntentionToolTip:
      "Intenções são objetivos do cliente e te ajudam a identificar qual departamento ele será atendido. Exemplo: Vendas, Suporte, Pós-Vendas.",
    MessageTag: "Título da mensagem",
    Entity: "Entidade",
    EntityKey: "Entidade chave",
    BotMessage: "Mensagem do bot",
    NewMessage: "Mensagem nova",
    Contacts: "Contatos",
    EntityTooltip:
      "As entidades são usadas para identificar partes importantes da conversa, como nomes, produto de interesse, datas etc. Para configurar entidade chave, insira um cifrão antes do termo, ex.: $unidade",
    // Inbox
    StartAttendance: "Iniciar Atendimento",
    InAttendance: "Em Atendimento",
    WaitingAttendance: "Aguardando Atendimento",
    AddConversation: "Adicionar conversa",
    AddConversationTitle: "Nova conversa",
    AddConversationSuccess: "Conversa criada com sucesso",
    AddConversationAlreadyExists:
      "Já existe uma conversa ativa com esse número",
    Confirm: "Confirmar",
    TotalConversations: "Total de conversas",
    TotalConversationsNumber: "Conversas",
    OpenedConversations: "Enviado para conversas em aberto",
    YourCompany: "Sua Empresa",
    YourPlan: "Seu Plano",
    StartConversation: "Iniciar conversa",
    SearchContact: "Buscar Contato",
    AppStoreHistory: "Histórico de Integrações",
    Channel: "Canal",
    StartDate: "Data Inicial",
    EndDate: "Data Final",
    ConversationHistory: "Histórico da Conversa",
    ConversationClosed: "Conversa encerrada",
    LastConversations: "Últimas conversas",
    VerifyDataAndTryAgain: "Verifique os dados inseridos e tente novamente",
    ongoing: "Aguardando",
    error: "Erro",
    success: "Sucesso",
    finished: "Finalizado",
    cancelled: "Cancelado",
    appHistoryReturnTitle: "Retorno Integração",
    ReturnCode: "Código de Retorno",
    YourName: "Seu Nome",
    YourEmail: "Seu Email",
    AladyHaveAccLoginInstead: "Já possui uma conta?",
    LoginInstead: "Faça o Login",
    CloseConversationSuccess: "Conversa encerrada com sucesso",
    ErrorOnCloseConversation:
      "Erro ao encerrar conversa, por favor recarregue a página e tente novamente",
    ErrorOnForwardConversation:
      "Erro ao encaminhar conversa, por favor recarregue a página e tente novamente",
    CreateNewChatbot: "Criar novo",
    Next: "Próximo",
    AtDepartment: "no departamento",
    AndAttendant: "e atendente",
    CompanyEmployees: "Quantidade de Atendentes",
    YouDontHaveAccess:
      "Você não tem acesso a este recurso, fale com o administrador da sua conta",
    Accept: "Aceitar",
    CloseConversationAccept: "Encerrar",
    CloseConversation: "Encerrar conversa",
    SelectClosingReason: "Selecione o motivo de encerramento",
    QuickMessages: "Mensagens Rápidas",
    AddQuickMessage: "Adicionar Mensagem",
    AddTemplate: "Adicionar Template",
    AutoMessages: "Mensagens Automáticas",
    Message: "Mensagem",
    ConfirmRemoveQuickMessage: "Tem certeza que deseja remover esta mensagem?",
    ConfirmRemoveTemplate: "Tem certeza que deseja remover esta template?",
    EditVariablesTemplates: "Editar variáveis",
    ChatGptSuggestionTemplatePlaceholder:
      "O que você precisa? ex: reengajar clientes",
    ErrorOnGeneratingChatGptSuggestion:
      "Algo deu errado. Por favor, tente novamente.",
    ConfirmInactivateAutoMessageTitle: "Desativar mensagem automática",
    ConfirmInactivateAutoMessage:
      "Tem certeza que deseja desativar esta mensagem?",
    InactivateSuccessfully: "desativou a mensagem com sucesso",
    ActivateSuccessfully: "ativou a mensagem com sucesso",
    ConfirmActivateAutoMessageTitle: "Ativar mensagem automática",
    ConfirmActivateAutoMessage: "Tem certeza que deseja ativar esta mensagem?",
    ConfirmActivate: "Tem certeza que deseja ativar esta opção?",
    ConfirmInactivate: "Tem certeza que deseja desativar esta opção?",
    FlowStart: "Início do Fluxo",
    OperatorForward: "Transferência de operador",
    CloseConversationNpsSurvey: "Encerrar conversa com NPS",
    CloseConversationCsatSurvey: "Encerrar conversa com CSAT",
    InvalidCoupon: "Cupom inválido",
    WhatsAppCompliantQRCode:
      "Antes de continuar a com a autenticação, você deve concordar com as politicas do Whatsapp de banimento de número no",
    WhatsAppCompliantQRCodeTerm: "Uso aceitável de nossos Serviços",
    WhatsAppCompliantQRCodeLink:
      "https://www.whatsapp.com/legal/terms-of-service?lang=pt-br#terms-of-service-acceptable-use-of-our-services",
    NumberDoesNotExist: "Não foi possível, este número não existe",
    SatisfactionSurveys: "Pesquisas de satisfação",
    Feedback: "Feedback",
    ViewInInbox: "Ver na inbox",
    CustomerSatisfaction: "Satisfação do usuário",
    TypeOfResearch: "Tipo de pesquisa",
    SearchName: "Procurar nome",
    FilterDates: [
      "Todos",
      "Esta semana",
      "Este mês",
      "Este ano",
      "Semana passada",
      "Mês passado",
      "Ano passado",
    ],
    NoContactFounded: "Nenhum contato encontrado",
    Operators: "Operadores",
    WhatsappNumber: "Número WhatsApp",
    SmsNumber: "Número SMS",
    Address: "Endereço",
    Description: "Descrição",
    Vertical: "Vertical",
    Websites: "Websites",
    Website: "Website",
    ProfilePhoto: "Foto de Perfil",
    WppApiVerticalAutomotive: "Automotivo",
    WppApiVerticalBeautySpaAndSalon: "Barbearia, Salão de Beleza e Spa",
    WppApiVerticalClothingAndApparel: "Roupas e Vestuário",
    WppApiVerticalEducation: "Educação",
    WppApiVerticalEntertainment: "Entretenimento",
    WppApiVerticalEventPlanningAndService: "Eventos",
    WppApiVerticalFinAnceandBanking: "Finanças e Bancos",
    WppApiVerticalFoodAndGrocery: "Alimentício",
    WppApiVerticalPublicService: "Serviço Publico",
    WppApiVerticalHotelAndLodging: "Hotelaria",
    WppApiVerticalMedicalAndHealth: "Medicina e Saúde",
    WppApiVerticalNonprofit: "Sem Fins Lucrativos",
    WppApiVerticalProfessionalServices: "Serviços Profissionais",
    WppApiVerticalShoppingAndRetail: "Compras e Varejo",
    WppApiVerticalTravelAndTransportation: "Viagens e Transporte",
    WppApiVerticalRestaurant: "Restaurante",
    WppApiVerticalOther: "Outro",
    About: "Sobre",
    SuccessWhatsappEditInfo: "Informações do WhatsApp salvas com sucesso",
    SuccessWhatsappEditPic: "Foto de perfil do WhatsApp salva com sucesso",
    WppApiTemplateCategory1:
      "Deixe os clientes cientes sobre atualizações feitas em suas contas",
    WppApiTemplateCategory2:
      "Mensagem automática para quando o seu negócio não está online para disponível para uma resposta no momento",
    WppApiTemplateCategory3: "Mensagens sobre finanças pessoais",
    WppApiTemplateCategory4: "Informações sobre atualizações em tickets",
    WppApiTemplateCategory5:
      "Envio de informações importantes ou novidades para os clientes",
    WppApiTemplateCategory6:
      "Resposta para perguntas, preocupações ou feedbacks dos clientes",
    WppApiTemplateCategory7:
      "Envio de confirmações, lembretes ou outras atualizações sobre alguma reserva do cliente",
    WppApiTemplateCategory8: "Envio de informações sobre viagens",
    WppApiTemplateCategory9:
      "Envio de confirmações, lembretes ou outras atualização sobre o agendamento do cliente",
    WppApiTemplateCategory10:
      "Envio de informações sobre questões de pagamento",
    WppApiTemplateCategory11: "Envio de informações sobre entrega de produtos",
    WppApiTemplateCategory12:
      "Envie atualizações de conta, atualizações de pedidos, alertas e muito mais para compartilhar informações importantes",
    WppApiTemplateCategory13:
      "Envie ofertas promocionais, anúncios de produtos e muito mais para aumentar a conscientização e o engajamento",
    WppApiTemplateCategory14:
      "(One-Time-Password) Envie códigos que permitem que seus clientes acessem suas contas",
    WppApiTemplateFit: "Qual dessas opções melhor se enquarda sua mensagem?",
    ChooseOneChatbot: "Escolha um chatbot",
    SubmitTemplate: "Submeter Template",
    Wpp24hWindowError:
      "A mensagem não foi enviada porque mais de 24 horas se passaram desde que o cliente respondeu pela última vez a este número",
    NoIntention: "outros",
    NewConversation: "Nova conversa",
    Ago: "atrás",
    Hours: "horas",
    Invitation: "Convite",
    ResubmitTemplate: "Ressubmeter Template",
    Status: "Status",
    Add: "Adicionar",
    AddedSuccessfuly: "Adicionado com sucesso",
    AddedBy: "Adicionado por",
    ConfirmDeleteIntentionTitle: "Deletear intenção",
    ConfirmDeleteIntention: "Tem certeza que deseja deletar essa intenção?",
    No: "Não",
    Yes: "Sim",
    Now: "Agora",
    Intentions: "Intenções",
    JsonPathText1:
      "JSONPath é uma linguagem de consulta para JSON. Você pode usar um caminho (ou expressão JSONPath) para recuperar o valor de determinada propriedade de uma resposta JSON (normalmente, você obterá uma resposta nesse formato de uma solicitação de API).",
    JsonPathText3: "Alguns exemplos de expressões JSONPath são os seguintes:",
    JsonPathText2:
      "O $ inicial representa o objeto raiz, o ponto (.) é usado para acessar as propriedades presentes dentro do JSON, o valor entre colchetes ([0]) representa o índice do array se a propriedade contiver um array.",
    Test: "Testar",
    EditedSuccessfuly: "Editado com sucesso",
    Entities: "Entidades",
    KeyEntities: "Entidades chave",
    UserRole: "Tipo",
    Manager: "Gerente",
    Operator: "Operador",
    UnknownName: "Sem Nome",
    AlreadyInAttendanceOrClosed: "Conversa já em atendimento ou encerrada",
    FoundedAlreadyInAttendance: "Conversa já está em atendimento",
    ErrorOnSendFileMessage: "Erro ao enviar arquivo. Tente novamente.",
    GenericErrorPleaseReportTitle: "Oops! Algo deu errado!",
    GenericErrorPleaseReportMessage:
      "Ajude-nos a melhorar reportando este erro.",
    YoureOffline: "Problema de conexão",
    NetworkIssues:
      "Verifique se seu computador está conectado a internet. Não é possível utilizar o sistema no momento",
    System: "Sistema",
    DownloadCSV: "Download CSV",
    DownloadXLSX: "Download XLSX",
    WebsocketConflict:
      'A Duotalk foi aberta em outra janela ou aba. Clique em "Usar aqui" para usar a Duotalk nessa janela',
    UseHere: "Usar aqui",
    Close: "Fechar",
    Warning: "Atenção",
    AttendanceByChannel: "Atendimentos por Canal",
    ConversationByChannel: "Conversas por Canal",
    NotPossibleToStartConversation:
      "Não foi possível inicar o atendimento. Tente novamente",
    NoneEntity: "Nenhuma entidade chave",
    others: "Outros",
    Campaigns: "Campanhas",
    Campaign: "Campanha",
    NewCampaign: "Nova Campanha",
    CampaignName: "Nome da Campanha",
    CampaignDescription: "Descrição da Campanha",
    ChooseTemplate: "Escolha o Template",
    CampaignData: "Dados da Campanha",
    ContactsData: "Dados dos Contatos",
    ReviewAndSend: "Revisão e Enviar",
    SubmitCampaign: "Disparar Campanha",
    Back: "Voltar",
    TotalContacts: "Total de Contatos",
    Errors: "Erros",
    CampaignAgreeBeforeContinueCSV:
      "Antes de continuar, você deve aceitar os termos.",
    CampaignCSVTerms:
      " Na Lei Geral de Proteção de Dados (LGPD) se faz necessário o registro de uma Base Legal associada à coleta e utilização de dados de um lead recebido. Ao continuar o disparo você garante que possui o Consentimento, Legitimo Interesse ou Contrato Preexistente bem como o OPT-IN para comunicação de cada um desses contatos.",
    Send: "Enviar",
    CampaignUploadCSV: "Upload Planilha",
    atTime: "às",
    File: "Arquivo",
    History: "Histórico",
    Answer: "Resposta",
    Answers: "Respostas",
    Waiting: "Aguardando",
    Finished: "Finalizado",
    Executing: "Executando",
    FileIsTooLarge: "Para este canal o arquivo deve ter até {0}MB",
    FileTypeXNotAllowdedChannel:
      "O tipo do arquivo {0} não é permitido neste canal",
    ChannelDontAcceptMedia: "Este canal não aceita envio de arquivos",
    ErrorOnLoadingConversations: "Erro ao carregar conversas. Tente novamente",
    ErrorAttendanceAnotherOperator:
      "Conversa em atendimento por outro operador",
    ErrorAttendanceAnotherOperatorOrNotFounded:
      "Conversa encerrada ou em atendimento por outro operador",
    UnexpectedErrorLoadConversationMessages:
      "Erro ao carregar mensagens, tente novamente",
    LinkRemovedSecured: "Link privado removido",
    AiGeneralSugestionLabel:
      "O que você gostaria que a nossa Inteligência Artificial soubesse para gerar melhores sugestões?",
    AiGeneralSugestionPlaceholder:
      "Exemplos: onde fica seu negócio? com o que você trabalha? Qual o horário de funcionamento Tamanho da empresa? e outras informações que considera importantes.",
    AiGeneralAnswerLabel: "Qual o estilo de resposta você deseja?",
    AiGeneralAnswerPlaceholder:
      "Exemplos: Somos informais mas gostamos de respostas curtas e objetivas.",
    AiPromptTemplateSugestionLabel: "Prompt para sugestão de template",
    AiPromptTemplateSugestionPlaceholder:
      "Crie uma mensagem de WhatsApp com no máximo 200 caracteres",
    AiPromptTemplateSugestionLegend:
      "O prompt acima será utilizado como base de todas as sugestões de template. Você pode alterá-lo sempre que quiser.",
    AiPromptAnswerSugestionLabel: "Prompt para sugestão de resposta",
    AiPromptAnswerSugestionPlaceholder:
      "Sugerir resposta para a ultíma pergunta do cliente no texto abaixo, em formato resumido e informal",
    AiPromptAnswerSugestionLegend:
      "O prompt acima será utilizado como base de todas as sugestões de template. Você pode alterá-lo sempre que quiser.",
    AiPromptChatSummaryLabel: "Prompt para resumo da conversa",
    AiPromptChatSummaryLegend:
      "O prompt acima será utilizado como base para resumir suas conversas na Inbox. Você pode alterá-lo sempre que quiser.",
    AiPromptSentimentAnalysisLabel: "Prompt para análise  de sentimento",
    AiPromptSentimentAnalysisLegend:
      "O prompt acima será utilizado como base para análise de sentimento. Você pode alterá-lo sempre que quiser.",
    AiPromptSentimentAnalysisPlacehold:
      "Veja o texto da conversa entre um cliente e um atendente, você deve fazer a análise do sentimento do cliente e dizer se é neutro, positivo ou negativo.",
    AiPromptFeedbackResumeLabel: "Prompt para resumo do feedback",
    AiPromptFeedbackResumeLegend:
      "O prompt acima será utilizado como base para resumir o feedback do cliente. Você pode alterá-lo sempre que quiser.",
    AiPromptFeedbackResumePlacehold:
      "Veja o feedback de um cliente sobre o atendimento, você deve resumir o feedback em poucas palavras.",
    SignOperator: "Assinatura de operador",
    AutomaticConversationDistribution: "Distribuição automática de conversas",
    Downtime: "Tempo de inatividade",
    MinMaxDays: "Mínimo de 1 dia, e máximo de 90 dias.",
    ReasonsForClosure: "Motivos de encerramento",
    ServiceAlerts: "Alertas de Atendimento",
    EditStatus: "Editar Status",
    AddNewReason: "Adicionar novo motivo",
    ReasonNameCannotBeEmpty: "O nome do motivo não pode estar vazio",
    ReasonWithThisNameAlreadyExists: "Um motivo com este nome já existe",
    AddReason: "Add Reason",
    NumberOfReasonsReached: "Número limite de motivos atingido.",
    AddNew: "Adicionar novo",
    RemoveReason: "Remover motivo",
    AreYouSureWantRemoveReason: "Tem certeza que deseja remover o motivo",
    ChooseDate: "Escolher Data",
    FilterByDate: "Filtre por data",
    SavedSuccessfuly: "Salvo com sucesso",
    SmartSecondContact: "Segundo contato inteligente",
    BlockContactCreation: "Bloquear conversa ativa para Operador",
    BlockContactTab: "Bloquear Aba de contato para Operador",
    BlockWaitingServiceTab:
      "Bloquear fila de 'Aguardando atendimento' para Operador",
    "Duotalk IA": "Duotalk IA",
    ConfirmEdit: "Tem certeza que deseja editar os dados?",
    Templates: "Templates",
    InfoDash:
      "Os dados apresentados são referente a D-1, ou seja, os dados de hoje só serão apresentados no dia seguinte.",
    CSVStructureError:
      "Verifique se o CSV está de acordo com o exemplo disponibilizado na pagina anterior.",
    Finish: "Finalizar",
    EmptyTable: "Tabela Vazia",
    Total: "Total",
    Template: "Template",
    InboxNoCampaignAvailableMsgP1: "Seus",
    InboxNoCampaignAvailableMsgP2: "disparos em massa",
    InboxNoCampaignAvailableMsgP3: "através do",
    InboxNoCampaignAvailableMsgP4: "módulo de Campanha",
    InboxNoCampaignAvailableMsgP5:
      "serão exibidos aqui até que o contato interaja com a mensagem de campanha",
    NoConversationFounded: "Nenhuma conversa encontrada",
    LoadMoreConversations: "Ver mais",
    AllConversationsLoaded: "Todas as conversas foram carregadas",
    SMSContentLabel: "Digite o conteúdo do SMS",
    Clicks: "Cliques",
    CampaignType: "Tipo de Campanha",
    InternalServerError:
      "Pedimos desculpas, mas nosso servidor encontrou um erro interno ao processar sua solicitação. Por favor, tente novamente mais tarde. Agradecemos sua paciência e compreensão. Se o problema persistir, sinta-se à vontade para entrar em contato com nossa equipe de suporte em [suporte@duotalk.io] para obter assistência adicional.",
    TryAgain: "Tentar Novamente",
    PageLoginWithFacebook: "Entrar com o Facebook",
    PageLoginWithFacebookPermissionsFail:
      "Falha no login, a integração necessita de todas as permissões requisitadas",
    WriteMessage: "Escreva uma mensagem",
    Enter: "Digite",
    AddAttendantsName: "para adicionar o nome do atendente e",
    ContactName: "para o nome do contato",
    TemplateHasCustomizableVariables:
      "Este template possui variáveis customizáveis.",
    HowItWorks: "Como funciona?",
    HowWouldLikeToReplace: "Como gostaria de substituí-la para este envio?",
    PreviewTemplate: "Prévia Template",
    Hide: "Esconder",
    Departments: "Departamentos",
    SomeConversationsMayNotAppear:
      "Algumas conversas podem não aparecer até a conclusão do processo desincronização",
    DropFilesHere: "Solte os arquivos aqui.",
    Postpone: "Adiar",
    PostponeTo: "Adiar para",
    Attendant: "Atendente",
    ForwardConversation: "Encaminhar Conversa",
    Forward: "Encaminhar",
    Respond: "Responder",
    BackToStart: "Voltar ao Início",
    StartService: "Iniciar Atendimento",
    Redo: "Refazer",
    SuggestAnswer: "Sugerir resposta",
    SeeContactsAndStartConversation: "Ver contatos e iniciar atendimento",
    EnterName: "Digite o nome",
    AddPhone: "Adicionar telefone",
    Transcribe: "Transcrever",
    Transcript: "Transcrição",
    SeeFullTranscript: "ver transcrição completa",
    TestChatbot: "Testar chatbot",
    TypeYourMessage: "Digite sua mensagem",
    GenerateSummary: "Gerar resumo",
    RedoSummary: "Refazer resumo",
    SummaryOfResearch: "Resumo das pesquisas",
    GeneratingQuickSummary:
      "Que tal gerar um resumo rápido de todas as suas respostas?",
    inbox: {
      emptyMessageInput:
        "O campo de mensagem está vazio, preencha-o por favor.",
      emptyMessage: "Campo vazio",
    },
    ComputerOffline: "Você está offline",
    ComputerOfflineExplain:
      "Verifique a conexão do seu computador e tente novamente.",
    Dialog360SignupErrorAPIGen: "Ocorreu um problema durante o processo de signup da Dialog 360. Por favor, tente novamente mais tarde.",
    InboxLoadSize: "Quantidade de mensagens carregadas na inbox",
    InboxLoadSizeInfo: "Quantidade de mensagens carregadas ao entrar na Inbox e cada requisição ao clicar em 'Ver Mais'",
    InboxLoadMinMax: "Mínimo 20 e máximo 1000",
    RemoveOperatorQueueAnswerLabel: "Tem certeza que deseja remover esse usuário da fila de distribuição automática?",
    AddOperatorQueueAnswerLabel: "Tem certeza que deseja adicionar esse usuário à fila de distribuição automática?",
    RemoveOperatorQueueAnswerTitle: "Remover usuário da fila",
    AddOperatorQueueAnswerTitle: "Adicionar usuário à fila",
    WebhookCall: "Chamada Webhook",
    OptionalParameters: "Parâmetros opcionais",
    AIUsageExample: "Exemplo de utilização",
    WebsiteInstalation: "Instalar no site",

    Channels: "Canais",
    ChatbotHintInstWJavaScript: "Instalar no site com JavaScript",
    ChatbotHintCopyTheCode: "Copie o código e cole antes do fechamento da tag",
    ChatbotHintOfYourSite: "do seu site",
    ChatbotHintInstallOnWordpress: "Instalar no Wordpress",
    ChatbotHintSendToTheDeveloper: "Enviar para o desenvolvedor",
    ChatbotHintIfYouPrefere: "Caso prefira, envie este código para alguém que possa instalar o código no seu site. Todas a instruções serão enviadas via email",
    SendCode: "Enviar Código",
    OtherPlatforms: "Outras Plataformas",
    ChatbotHintOtherPlatforms: "Seu chatbot pode ser integrado a outras plataformas",
    CheckOurAppStore: "Confira na nossa App store as opções disponíveis",
    GoToAppStore: "Ir para App store",
    Done: "Feito",
    WhatsappOptions: "Opções do WhatsApp",
    NotifyOptions: "Receba as notificações por e-mail ou celular. Marque as opções que você deseja ser notificado.",
    NotifyMessage: "Desejo ser notificado sempre que uma nova mensagem seja enviada",
    NotifyTransfer: "Desejo ser notificado sempre que uma transferência seja feita",
    NotifySurveyResponse: "Desejo ser notificado sempre que uma nova resposta pesquisa de satisfação seja feita",
    InboxConversationsSyncAlert: "Algumas conversas pondem não aparecer até a conclusão do processo de sincronização.",
    SaveFlowAndReturn: "Salvar e Retornar",
  }
};
